/* styles.css */

.btn-group .btn {
  background-color: #e9e9e9;
  /* Light pink color for inactive buttons */
  border-color: #e9e9e9;
  /* Set border color to match background color */
  font-weight: bold;
}

.btn-group .btn.active {
  background-color: #ff725e;
  /* Highlight color for active button */
  border-color: #ff725e;
  /* Set border color to match background color */
}

.custom-btn-color {
  background-color: #ff725e;
  /* FF725E color */
  color: black;
  /* Default text color */
}

.custom-btn-color.active {
  color: white;
  /* Text color when button is active */
}

.btnGrp1 {
  margin-top: 1.5px;
}

.btn-group {
  display: inline-block;
  white-space: nowrap;
}

.black-text {
  color: black;
}

/* group Icon */

/* .groupIcon {
    position: relative;
    background-color: rgb(246, 204, 204);
    width: fit-content;
    height: fit-content;
    padding: 5px;
    align-items: center;
    justify-content: center;    
    border-radius: 0.5rem;

  } */

.userIcon {
  position: relative;
  background-color: rgb(246, 204, 204);
  width: 2.1rem;
  height: 2.1rem;
  padding: 5px;
  /* margin-left: 10px; */
  /* top: 10px; */
  align-items: center;
  justify-content: center;
  border-radius: 12rem;
  margin-left: -13%;
  margin-top: -5.5%;
}

.graphIcon {
  position: relative;
  background-color: rgb(246, 204, 204);
  align-items: center;
  display: flex;
  width: 2.1rem;
  height: 2.1rem;
  padding: 5px;
  border-radius: 50%;
  /* Use 50% for a perfect circle */
  justify-content: center;
  margin-right: 8px;
}

.hospitalIcon {
  position: relative;
  background-color: rgb(246, 204, 204);
  align-items: center;
  display: flex;
  width: 2.1rem;
  height: 2.1rem;
  padding: 5px;
  border-radius: 50%;
  /* Use 50% for a perfect circle */
  justify-content: center;
  margin-right: 8px;
}

/* Primary Data Filters Card */
.primaryDataCard {
  /* width: 90rem; */
  /* height: 3.5rem;
    margin-top: -3.2%; */
  display: flex;
  box-shadow: none;
  background-color: #fbf8f8;
  /* margin-left: 10%; */
  margin-bottom: 1%;
}

/* Primary Data Filters Card for screens smaller than 768px */
@media screen and (max-width: 768px) {
  .primaryDataCard {
    width: 100%;
    /* Adjust as needed for smaller screens */
    margin-top: -20px;
    /* Adjust as needed */
  }
}

.filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-right: 28%; */
  width: 94%;
}

/* Primary Data Filters Card Body */
.primaryDataCardBody {
  /* margin-left: 1%; */
  font-size: 18px;
  font-family: sans-serif;
  color: skyblue;
  font-weight: bold;
  display: flex;
  grid-gap: 33%;
  /* gap: 33%; */
  justify-content: flex-end;
  width: 28%;
}

.profilemain_container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.profilemain_container1 {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 820px) {
  .primaryDataCardBody {
    font-size: 18px;
    font-family: sans-serif;
    color: skyblue;
    font-weight: bold;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;
  }

  .btn-group {
    display: flex;
    white-space: nowrap;
  }
}

@media screen and (max-width: 1065px) {
  .filter {
    flex-direction: column;
  }

  /* .pageheading {
  } */
  .primaryDataCardBody {
    justify-content: center;
    width: 94%;
    /* margin: 0;
    font-size: 18px;
    font-family: sans-serif;
    color: skyblue;
    font-weight: bold;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap; */
  }

  .btn-group {
    /* display: flex;
    white-space: nowrap; */
  }
}

@media screen and (max-width: 1380px) {

  /* .primaryDataCardBody {
      font-size: 18px;
        font-family: sans-serif;
        color: skyblue;
        font-weight: bold;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-content: center;
        flex-wrap: wrap;
    }
    .btn-group {
      display: flex;
      white-space: nowrap;
    }
    .dashboardSection1{
      display: flex;
      justify-content: center;
      align-items: center;
    } */
  .houseHoldCard {
    width: 100% !important;
  }

  .healthWorkers {
    width: 100% !important;
    padding: 10px;
  }

  .healthWorkers1 {
    width: 100% !important;
  }

  .groupIcon {
    font-size: 1.5rem;
    /* Adjust size of the icon */
  }

  .healthWorkers p {
    font-size: 18px;
    /* Default font size for smaller screens */
  }


}

/* Primary Data Filters Buttons */
.primaryButton {
  cursor: pointer;
  border: none;
  padding: 6px 10px 6px 10px;
  border-radius: 4px;
  font-size: 16px;
  margin-left: 25px;
  background-color: #ff725e;
  color: white;
}

/* Primary Button Group for screens smaller than 768px */
.primaryButtonGroup {
  margin-top: -40px;
  margin-left: 40rem;
}

/* Primary Button Styles for screens smaller than 768px */
@media screen and (max-width: 768px) {
  .primaryButton {
    margin-bottom: 5px;
    /* Add space between buttons for smaller screens */
  }


}



/* HouseHold Completion Status Card */
.houseHoldCard {
  width: auto;
  /* Full width for responsiveness */
  height: auto;
  box-shadow: none;
  border-radius: 8px;
  /* margin-left: 50%; */
  background-color: #8a72db;
  margin-top: 1%;
}



/* HouseHold Completion Status Card Body */
.houseHoldCardBody {
  display: flex;
  flex-direction: row;
  gap: 5%;
  flex-wrap: wrap;
}

/* HouseHold Completion Status Text Container */
.houseHoldTextContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 150px;
}

/* HouseHold Completion Status Title */
.houseHoldTitle {
  font-weight: bold;
  color: white;
  font-size: 1.2em;
  /* word-wrap: break-word; */
  margin-top: -32px;
  width: "60px";
}

/* HouseHold Completion Status Content */
.houseHoldContent {
  color: white;
  text-align: left;
}

/* HouseHold Completion Status Performance Text */
.houseHoldPerformanceText {
  font-size: 13px;
  color: #d6d0e5;
}

/* HouseHold Completion Status Percentage */
.houseHoldPercentage {
  font-size: 28px;
  color: white;
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .houseHoldCardBody {
    flex-direction: column;
    /* Stack content vertically on small screens */
    gap: 10px;
  }

  .houseHoldTitle {
    font-size: 1em;
    /* Reduce title size */
  }

  .countbox {
    font-size: 0.8em;
    /* Smaller font for countbox */
  }
}

/* HouseHold Completion Status Percentage Value */
.houseHoldPercentageValue {
  color: #f5b849;
}

/* HouseHold Completion Status CircularProgressbar Container */
.houseHoldCircularProgressbarContainer {
  width: 130px;
  height: 100px;
  margin-top: -4px;
  margin-left: 10px;
  flex-direction: column;
}

.houseHoldCircularProgressbarContainer.withColor {
  background-color: your-color-if-true;
}

/* Circular Progressbar */
.circularProgressbar {
  width: 90px;
  height: 80px;
  /* background-color: white;  */
  margin-top: -2px;
  margin-left: 10px;
  flex-direction: column;
}

.circularProgressbar path {
  stroke: orange !important;
  transition: stroke-dashoffset 0.5s ease 0s;
  transform: rotate(0 turn);
  transform-origin: center center;
}

.circularProgressbar text {
  fill: white !important;
}

/* group Icon */

.groupIcon {
  position: relative;
  background-color: rgb(246, 204, 204);
  width: fit-content;
  height: fit-content;
  padding: 5px;
  /* top: 10px; */
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
}

/* Total Health Workers Card */
.totalHealthWorkersCard {
  width: 17rem;
  height: 10rem;
  box-shadow: none;
  border-radius: 8px;
  margin-left: 7%;
  /* background-color: #8A72DB; */
  font-family: "Lato, sans-serif";
  margin-top: -20px;
}

/* Total Health Workers Card Body */
.totalHealthWorkersCardBody {
  color: white;
  font-family: "roboto";
}

/* Total Health Workers Icon */
.totalHealthWorkersIcon {
  width: 15%;
  height: 10%;
  font-size: 20px;
  margin-left: -5px;
  margin-top: -0.8rem;
}

/* Total Health Workers Text */
.totalHealthWorkersText {
  font-size: 12px;
  margin-left: 38px;
  color: #333335;
  margin-top: -32px;
  font-weight: bold;
}

/* Total Health Workers Count */
.totalHealthWorkersCount {
  display: block;
  font-size: 50px;
  margin-top: -40px;
  margin-left: 8.5rem;
  color: skyblue;
  font-family: "roboto";
}

/* Total Health Centers Card */
.totalHealthCentersCard {
  width: 17rem;
  height: 10rem;
  box-shadow: none;
  border-radius: 8px;
  margin-left: -8rem;
  margin-top: -20px;
  font-family: "Lato, sans-serif";
}

/* Total Health Centers Card Body */
.totalHealthCentersCardBody {
  color: white;
  font-family: "roboto";
}

/* Health Centers Icon */
.totalHealthCentersIcon {
  width: 12%;
  height: 8%;
  margin-left: -5px;
  margin-top: -16px;
}

/* Health Centers Text */
.healthCentersText {
  font-size: 15.5px;
  margin-top: -32px;
  /* fontFamily: "roboto "; */
  margin-left: 32px;
  color: #333335;
  font-weight: bold;
}

/* Health Centers Count */
.healthCentersCount {
  display: block;
  font-size: 50px;
  margin-top: -10px;
  margin-left: 8.5rem;
  color: skyblue;
  font-family: "roboto";
}

/* View All Text */
.viewAllText {
  font-size: 13px;
  margin-left: 144px;
  color: skyblue;
  margin-top: -16px;
}

/* DETAILS container */
.detailsContainer {
  display: flex;
  /* margin-left: 170px; */
}

/* DETAILS card */
.detailsCard {
  /* width: 72rem; */
  height: 31.3rem;
  box-shadow: none;
  margin-left: -0.8%;
  padding: 0;
  border-radius: 8px;
}

/* DETAILS card header */
.detailsCardHeader {
  background-color: white !important;
  color: black !important;
  font-size: 16px;
  border-radius: 8px;
}

/* DETAILS card body */
.detailsCardBody {
  overflow: scroll;
}

.graphCard {
  margin-top: 9px;
  /* margin-left: 1%; */
  border-radius: 8px;
}

.userFirstName {
  font-size: 16px;
  font-family: sans-serif;
  font-weight: bold;
}

/* User email */
.userEmail {
  margin-left: 4%;
  color: #8c9097;
}

/* 
.userMobileNo{
    margin-left: 15%;
    margin-top: -3.5%;
} */

/* HEALTH WORKERS card */
.healthWorkersCard {
  /* width: 17rem; */
  height: 10rem;
  padding: 8%;
  border-radius: 8px;
  box-shadow: none;
  /* margin-left: -116px; */
}

/* HEALTH WORKERS card body */
.healthWorkersCardBody {
  color: #23b7e5;
  font-size: 20px;
  gap: 1rem;
  flex-direction: column;
  margin-left: -5px;
}

/* HEALTH WORKERS text container */
.healthWorkersTextContainer {
  margin-right: 3rem;
}

/* HEALTH WORKERS city text */
.healthWorkersCityText {
  font-size: 14px;
  margin-top: -30px;
  font-weight: bold;
  margin-left: -10px;
}

/* HEALTH WORKERS city name */
.healthWorkersCityName {
  font-size: 20px;
  margin-top: 20px;
  margin-left: -8px;
  margin-bottom: 90px;
  font-family: roboto;
}

/* HEALTH WORKERS user count */
.healthWorkersUserCount {
  font-size: 2rem;
  margin-top: 20px;
  margin-left: -0.5rem;
  font-family: roboto;
}

/* styles.css */

/* New card container */
.newCardContainer {
  margin-right: -35px;
}

/* New card */
.newCard {
  width: 19rem;
  height: 30rem;
  box-shadow: none;
  border-radius: 8px;
  margin-left: 38rem;
  /* Adjust the margin as needed */
  margin-top: -21.5rem;
}

/* New card body */
/* .newCardBody {
} */

.custom-card {
  width: 37.3vw;
  height: 60vh;
  margin-top: -100px;
  margin-left: -1%;
  /* box-shadow: none; */
  border-radius: 8px;
}

.card-header {
  background-color: rgb(246, 242, 242) !important;
  color: black;
  font-size: 16px;
  border-top-left-radius: 8px;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.vizagCard2 {
  width: 17rem;
  height: 10rem;
  border-radius: 8px;
  box-shadow: none;
  margin-bottom: 20px;
  margin-left: 5rem;
}

.vizagCard1 {
  width: 19rem;
  height: 10rem;
  border-radius: 8px;
  /* box-shadow: none; */
  margin-bottom: 20px;
  margin-left: 5%;
}

/*   
  Apply margin to all cards equally
  .vizagCard:nth-child(n) {
    margin-top: 0;
    margin-left: 15rem;
  }
  
  .vizagCard:first-child {
    margin-top: -20px; 
  }
  
  .vizagCard:nth-child(2) {
    margin-top: -20px; 
  }
  
  .vizagCard:nth-child(3) {
    margin-top: -20px; 
  } */

.vizagCardBody {
  margin-top: 10px;
  margin-left: 25px;
}

.vizagCardCountText {
  font-size: 50px;
  color: skyblue;
  margin-left: 10.8rem;
  margin-top: 10rem;
}

.sonipatCard {
  width: 20rem;
  height: 15rem;
  margin-left: 13rem;
  margin-top: 5rem;
  box-shadow: none;
}

.sonipatCardBody {
  margin-top: 10px;
  margin-left: 25px;
}

.houseHoldCardInfluenza {
  width: 20rem;
  height: 9rem;
  margin-left: 2%;
  margin-top: 1.5rem;
  box-shadow: none;
  border-radius: 8px;
  font-family: "Lato, sans-serif";
}

.houseHoldCardInfluenza1 {
  width: 20rem;
  height: 9rem;
  margin-top: 1.5rem;
  margin-left: 2%;
  box-shadow: none;
  border-radius: 8px;
  font-family: "Lato, sans-serif";
}

.houseHoldCardInfluenza2 {
  width: 20rem;
  height: 9rem;
  margin-top: 1.5rem;
  margin-right: 19.5rem;
  box-shadow: none;
  border-radius: 8px;
  font-family: "Lato, sans-serif";
}

.houseHoldCardInfluenza3 {
  width: 20rem;
  height: 9rem;
  margin-top: 2rem;
  margin-left: 7%;
  border-radius: 8px;
  box-shadow: none;
  font-family: "Lato, sans-serif";
}

.houseHoldCardInfluenza4 {
  width: 20rem;
  height: 9rem;
  box-shadow: none;
  border-radius: 8px;
  font-family: "Lato, sans-serif";
  margin-left: 1%;
  margin-top: 6.5%;
}

.countText {
  font-weight: bold;
  font-size: 40px;
  color: skyblue;
  /* margin-left: 80%; */
  font-family: "roboto";
}

.sentinnelCard {
  width: 20rem;
  height: 9rem;
  margin-left: 1%;
  margin-top: 1rem;
  box-shadow: none;
  border-radius: 8px;
  font-family: "Lato, sans-serif";
}

.sentinnelCard1 {
  width: 20rem;
  height: 9rem;
  margin-left: 2%;
  margin-top: 1rem;
  box-shadow: none;
  border-radius: 8px;
  font-family: "Lato, sans-serif";
}

.sentinnelCardTextOpd {
  font-size: 24px;
  margin-top: -10px;
  font-weight: bold;
  margin-left: -1px;
  color: #333335;
}

.sentinnelCardTextOxygen {
  color: #333335;
  font-size: 24px;
  font-weight: bold;
  margin-top: -10px;
  margin-left: -1px;
}

.sentinnelCardTextIcu {
  color: #333335;
  font-size: 24px;
  font-weight: bold;
  margin-top: -10px;
  margin-left: -1px;
}

.sentinnelCardTextDeaths {
  color: #333335;
  font-size: 24px;
  font-weight: bold;
  margin-top: -10px;
  margin-left: -1px;
}

.sentinnelCardTextSariPatients {
  color: #333335;
  font-size: 24px;
  font-weight: bold;
  margin-top: -10px;
  margin-left: -1px;
}

.sentinnelCardTextPneumonia {
  color: #333335;
  font-size: 24px;
  font-weight: bold;
  font-family: "Lato, sans-serif";
  margin-top: -10px;
}

.houseHoldCardText {
  color: #333335;
  font-size: 21px;
  font-weight: bold;
  /* margin-top: -12px; */
  /* margin-left: -2%; */
  font-family: "roboto";
}

.houseHoldCardText1 {
  color: #333335;
  font-size: 24px;
  font-weight: bold;
  margin-top: -0.8rem;
  font-family: "roboto";
}

.houseHoldCardText2 {
  color: #333335;
  font-size: 24px;
  font-weight: bold;
  margin-top: -0.8rem;
  margin-left: -3%;
  font-family: "roboto";
}

.houseHoldCardText3 {
  color: #333335;
  font-size: 24px;
  font-weight: bold;
  margin-top: 2.5rem;
}

.houseHoldCardText4 {
  color: #333335;
  font-size: 24px;
  font-weight: bold;
  margin-top: 2rem;
  font-family: "roboto";
}

.sentinnelCardTextNasalSwab {
  color: #333335;
  font-size: 24px;
  font-weight: bold;
  margin-top: -10px;
  margin-left: -1px;
}

.sentinnelCardTextSamples {
  color: #333335;
  font-size: 20px;
  font-weight: bold;
  margin-top: -10px;
  margin-left: -1px;
}

.sentinnelCard2 {
  width: 20rem;
  height: 9rem;
  margin-left: 3%;
  margin-top: 1rem;
  box-shadow: none;
  border-radius: 8px;
  font-family: "Lato, sans-serif";
}

.sentinnelCard3 {
  width: 20rem;
  height: 9rem;
  margin-left: 5.5%;
  margin-top: 32px;
  box-shadow: none;
  border-radius: 8px;
  font-family: "Lato, sans-serif";
}

.sentinnelCard4 {
  width: 20rem;
  height: 9rem;
  margin-left: 20%;
  margin-top: 32px;
  box-shadow: none;
  border-radius: 8px;
  font-family: "Lato, sans-serif";
}

.sentinnelCard5 {
  width: 20rem;
  height: 9rem;
  margin-left: 35%;
  margin-top: 32px;
  box-shadow: none;
  border-radius: 8px;
  font-family: "Lato, sans-serif";
}

.sentinnelCard6 {
  width: 20rem;
  height: 9rem;
  margin-left: 5%;
  margin-top: 32px;
  box-shadow: none;
  border-radius: 8px;
  font-family: "Lato, sans-serif";
}

.sentinnelCard7 {
  width: 20rem;
  height: 9rem;
  margin-left: 20%;
  margin-top: 10%;
  box-shadow: none;
  border-radius: 8px;
  font-family: "Lato, sans-serif";
}

.sentinnelCard8 {
  width: 20rem;
  height: 9rem;
  margin-left: -51rem;
  margin-top: -9rem;
  box-shadow: none;
  border-radius: 8px;
  font-family: "Lato, sans-serif";
}

.row {
  margin-left: 0;
  margin-left: 0;
  margin: 0;
}

.dashboardCardsMain {
  /* margin: 0 5%; */
  margin-bottom: 1%;
}

.dashboardCards {
  display: flex;
  /* margin-top: 2%; */
  gap: 2%;
  padding: 0;
  align-items: center;
}

.dashboardCards .MiniTwoCards {
  display: flex;
  margin-top: 3%;
}

.detailsContainerMain {
  display: flex;
  justify-content: center;
}

.ILIsymptomaticpatients {
  background: white;
  padding: 20px;
  margin: 2% 0;
  border-radius: 10px;
  flex: 1;
  width: 50%;
}

.ILIsymptomaticpatients .headding {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ILIsymptomaticpatients .headding h1 {
  color: black;
  font-size: 25px;
  font-weight: bolder;
  margin-bottom: 0;
}

.ILIsymptomaticpatients button {
  padding: 4px 24px;
  border: none;
  border-radius: 6px;
  margin: 0px 7px;
  font-size: medium;
}

.DynamicGraphList {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.PatientsTestedInfluenza {
  background: white;
  padding: 20px;
  margin: 2% 0;
  border-radius: 10px;
  flex: 1;
  width: 50%;
}

.PatientsTestedInfluenza .headding {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PatientsTestedInfluenza .headding h1 {
  color: black;
  font-size: 25px;
  font-weight: bolder;
  margin-bottom: 0;
}

.PatientsTestedInfluenza button {
  padding: 4px 24px;
  border: none;
  border-radius: 6px;
  margin: 0px 7px;
  font-size: medium;
}

@media screen and (max-width: 1461px) {

  .PatientsTestedInfluenza .headding h1,
  .ILIsymptomaticpatients .headding h1 {
    font-size: 20px;
  }
}

@media screen and (max-width: 1335px) {

  .PatientsTestedInfluenza .headding h1,
  .ILIsymptomaticpatients .headding h1 {
    font-size: 18px;
  }

  .PatientsTestedInfluenza button,
  .ILIsymptomaticpatients button {
    padding: 4px 19px;
    font-size: small;
    margin: 0px 5px;
  }
}

@media screen and (max-width: 1188px) {

  .PatientsTestedInfluenza .headding h1,
  .ILIsymptomaticpatients .headding h1 {
    font-size: 16px;
  }

  .PatientsTestedInfluenza button,
  .ILIsymptomaticpatients button {
    padding: 4px 10px;
    font-size: 11px;
    margin: 0px 3px;
  }
}