/* .sidenav-container {
  overflow: hidden; 
}

.sidenav-container:hover .sidenav {
  width: -250px;
  transform: translateX(0);
} */
.sidenav-container_Main {
  margin-left: 15% !important;

}

.no-arrow .fa-angle-down {
  display: none;
}


.white-skin .navbar {
  margin-left: 240px;
}