.sidenav {
    background-color: #111C43!important;
    transition: width 0.3s ease; /* Define the transition property */
}


.usertable td{
    background-color:white ;
    font-family:Arial;
    font-size: 18px;
    border: 1px solid ;
    padding: 5px;
    
    
}

.usertable th {
    font-size: 22px; 
    color:rgb(12, 56, 4);
    padding: 5px;
    border: 1px solid black;;
}


