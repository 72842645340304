
.groupIcon {
    /* position: relative;
    background-color: rgb(246, 204, 204);
    width: 2.1rem;
    height: 2.1rem;
    padding: 5px;
    margin-left: 10px;
    /* top: 10px; */
    /* align-items: center;
    justify-content: center;    
    border-radius: 12rem;
    margin-top: -1%;  */
    padding: 12px;
    display: flex;
    align-items: center;

  }


  .groupIcon box-icon {
    position: relative;
    background-color: rgb(246, 204, 204);
    width: 1.6rem;
    height: 1.6rem;
    /* padding: 10px; */
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  

  .healthWorkers {
    /* width: 19rem; */
    width: 24%;
    height: 80%;
    border-radius: 8px;
    margin-top: 1%;
    box-shadow: none;
    /* margin-left: 32.5%; */
  }

  .healthWorkers1 {
    width: 19rem;
    height: 10rem;
    border-radius: 8px;
    margin-top: 1%;
    box-shadow: none;
    /* margin-left: 14.5%; */
  }
  .viewAllText{
    font-size: 18px;
  }
