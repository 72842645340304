.loginCard {
    height: 325px;
    padding: 20px;
    width: 28rem;
    border-radius: 8px;
    margin-top: 50px;
    border-radius: 8px;
}

.loginBtn {
    padding: 8px;
    width: 5rem;
    height: 2.5rem;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    margin-top: -3%;
}


.textCenter {
    text-align: center;
    border: none;
    font-size: 25px;
    font-family: sans-serif;
    color: white;
    width: 28rem;
    border-radius: 8px;
    margin-top: -4.8%;
    margin-left: -4.8%;
    background-color: #FF725E !important;
    letter-spacing: 2px;
}


/* style={{
    marginTop: "-15px",
    border: "none",
    fontSize: "25px",
    fontWeight: "bold",
    fontFamily: "sans-serif",
    color: "red",
    backgroundColor: "#FFFFFF",
    alignItems: "center",
  }}  */

.landing-section{
    overflow: hidden;
  }
  
.landing-inner-section{
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    align-items: center;
    color: #FF725E;
}

.landing-heading{
    /* position: fixed; */
}

.landing-logo{
    width: 200px;
    height: 100px; 
    /* margin: 10px; */
}
.clinally-logo{
        width: 100px;
        height: 100px;
}
.inner-div{
        margin: 0 auto;
        flex-direction: column;
        text-align: center;
        margin-top: -5rem;
        color: #FF725E;
}
.dashboard{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto;
    margin-top: 1%;
    justify-content: center;
    gap: 7%;
    margin-right: 2%;

}
.inner-dashboard-structure{
        /* width: 700px;
        height: 700px; */
        /* margin-top: -20px;
        position: fixed; */
}
/* .structure-section{
        flex-direction: column;
        margin-top: 8rem;
        margin-right: 0.8rem;
} */
.landing-card{
        border: 1px solid #ced4da;
        border-radius: .25rem;
        transition: all 0.3s;
        margin-top: -2rem;
        padding: 0.5rem;
        padding-top: 1rem;
        padding-bottom: 0.5rem;
        border: none,
}
.password-input{
        border: 1px solid #ced4da;
        border-radius: .25rem;
        transition: all 0.3s;
}

.login-container{
        display: flex;
        justify-Content: center;
}

.dashboard-item {
    display: flex;
    /* flex-grow:1; */
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 1230px) {
    .dashboard {    
        width:100%;
        flex-wrap: wrap-reverse;
    }
}

@media screen and (max-width: 930px) {
    .inner-div {    
        margin-top: 0.5rem !important;
    }
    
}
