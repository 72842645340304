body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MDBDataTable .usertable table tbody tr td {
  text-align: center;
}

table.table-sm th,
table.table-sm td {
  padding-top: .6rem;
  padding-bottom: .6rem;
  align-content: center;
  font-size: 16px;
  /* text-align: center; */
}

table.table-sm th {
  font-weight: bold;
}

.usertable td,
.usertable th {
  border: 1px solid #00000036 !important;
}

.d-flex {
  display: flex !important;
  gap: 4%;
}

.custom-spinner .spinner-border {
  border-color: #FF725E !important;
  /* Change spinner border color */
}

.custom-spinner .spinner-border span {
  border-top-color: #FF725E !important;
  /* Change spinner top color */
}

.custom-spinner .spinner-border {
  border-top-color: red !important;
}

table.table th,
table.table td {
  padding-top: 1.1rem;
  padding-bottom: 1rem;
  /* align-content: center; */
}