.Footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  background-color: gray;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s;
}

.Footer.visible {
  opacity: 1;
}


/* Footer.css

body {
    overflow-x: hidden;
  }
  
  .custom-footer {
    bottom: -100px; 
    width: 100%;
    z-index: 1;
    margin-left: 10%; 
    transition: bottom 0.3s ease-in-out;
    background-color: gray !important;
  }

  .footer-copyright a {
    display: inline-block; 
  }
  
   */