.usertable2 td {
  background-color: white;
  font-family: Arial;
  font-size: 10px;
  border: 1px solid rgb(252, 231, 231);
  padding: 5px;
}

.usertable2 th {
  font-size: 15px;
  color: rgb(12, 56, 4);
  padding: 5px;
  border: 1px solid black;
}
.testpagemain {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.testpage input,
.testpage button {
  border-radius: 8px;
  border: 1px solid;
  margin-left: 0.5%;
}
.testpage {
  display: flex;
  align-items: center;
  width: 70%;
  justify-content: flex-end;
}
