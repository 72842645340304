
/* .card-header:first-child {
   border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
   background-color: #FF725E !important;
} */

/* styles.css */

.input-label {
   position: absolute;
   top: 16.5%;
   transform: translateY(-50%);
   left: 116px;
   pointer-events: none;
   transition: 0.2s;
   font-size: 15px;
   color: gray;
   font-family: sans-serif;
 }
 
 .input-label-last {
   position: absolute;
   top: 16.5%;
   transform: translateY(-50%);
   left: 23.5rem;
   pointer-events: none;
   transition: 0.2s;
   font-size: 15px;
   color: gray;
   font-family: sans-serif;
 }

 .input-label-email {
   position: absolute;
   top: 16.5%;
   transform: translateY(-50%);
   left: 39.5rem;
   pointer-events: none;
   transition: 0.2s;
   font-size: 15px;
   color: gray;
   font-family: sans-serif;
 }
 
 .input-label-phone {
   position: absolute;
   top: 41%;
   transform: translateY(-50%);
   left: 7.5rem;
   pointer-events: none;
   transition: 0.2s;
   font-size: 15px;
   color: gray;
   font-family: sans-serif;
 }
 
 .input-label-gender {
   position: absolute;
   top: 41%;
   transform: translateY(-50%);
   left: 23.5rem;
   pointer-events: none;
   transition: 0.2s;
   font-size: 15px;
   color: gray;
   font-family: sans-serif;
 }
 
 .input-label-city {
   position: absolute;
   top: 41%;
   transform: translateY(-50%);
   left: 39.8rem;
   pointer-events: none;
   transition: 0.2s;
   font-size: 15px;
   color: gray;
   font-family: sans-serif;
 }

 .input-label-state {
   position: absolute;
   top: 65.5%;
   transform: translateY(-50%);
   left: 7.5rem;
   pointer-events: none;
   transition: 0.2s;
   font-size: 15px;
   color: gray;
   font-family: sans-serif;
 }
 
 .input-label-region {
   position: absolute;
   top: 65.5%;
   transform: translateY(-50%);
   left: 23.6rem;
   pointer-events: none;
   transition: 0.2s;
   font-size: 15px;
   color: gray;
   font-family: sans-serif;
 }
 
 .input-label-role {
   position: absolute;
   top: 65.5%;
   transform: translateY(-50%);
   left: 40rem;
   pointer-events: none;
   transition: 0.2s;
   font-size: 15px;
   color: gray;
   font-family: sans-serif;
 }
 /* Form container */
.user-form-container {
  margin: 20px auto;
  max-width: 1200px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Form row */
/* .user-form-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
} */

.user-form-row {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  gap: 3%;
}

/* Form field */
.user-form-field {
  display: flex;
  flex-direction: column;
  width: 30%; /* Ensures three fields per row */
}

/* Label */
.user-form-label {
  margin-bottom: 5px;
  font-weight: bold;
}

/* Input field */
.user-form-input, 
.user-form-select {
  height: 40px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}

/* Button */
.user-form-button {
  margin-top: 20px;
  width: 150px;
  height: 45px;
  border-radius: 5px;
  border: none;
  background-color: #FF724E;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

/* Success popup */
.user-form-success-popup {
  background: green;
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .user-form-row {
    flex-direction: column;
    align-items: center;
  }

  .user-form-field {
    width: 100%;
    margin-bottom: 10px;
  }

  .user-form-button {
    width: 100%;
  }
}
